import React, { useEffect, useState } from "react";
import lockingPeriodBg from "../../assets/lockingBg.png";
import { toast } from "react-hot-toast";
import coinBox from "../../assets/coinBox.png";
import lock from "../../assets/lock.svg";
import { ethers } from "ethers";
import tokenLockerAbi from "./TokenLockerAbi";
import { useQueryClient } from "@tanstack/react-query";
import MetaMaskSDK from "@metamask/sdk";
import TokenLockPopup from "./Pop-Up/TokenLockPopup";
import SuccessPopup from "./Pop-Up/TokenlockSuccess";
import FaildPopup from "./Pop-Up/FailedLock";
import { getBalance } from "../../services/balance";
import { useQuery } from "@tanstack/react-query";
import useMetaMask from "./useMetaMask";
import { useSelector } from "react-redux";

const LockingPeriod = () => {
  const signer = useSelector((state) => state.signer.signer);
  const queryClient = useQueryClient();
  const [address, setAddress] = useState(
    localStorage.getItem("address") || null
  );
  const [balance, setBalance] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [transactionResult, setTransactionResult] = useState("");
  const [lockData, setLockData] = useState({
    tokenQuantity: "",
    months: "",
  });
  const [error, setError] = useState({
    tokenQuantity: "",
    months: "",
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: ["balance"],
    queryFn: () => getBalance(address),
    enabled: !!address,
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  useEffect(() => {
    if (data?.balance) {
      setBalance(data.balance);
      queryClient.invalidateQueries(["userDetails"]);
      console.log("Fetched balance:", data.balance);
    }
  }, [data]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "tokenQuantity") {
      const validNumber = /^\d+(\.\d{0,2})?$/;

      if (value === "" || validNumber.test(value)) {
        if (parseFloat(value) > parseFloat(balance)) {
          setError({
            ...error,
            tokenQuantity: "Insufficient balance",
          });
        } else {
          setError({ ...error, tokenQuantity: "" });
        }
        setLockData((prevData) => ({ ...prevData, [name]: value }));
      } else {
        setError({
          ...error,
          tokenQuantity: "Only two decimal places are allowed",
        });
      }
    }

    if (name === "months") {
      setLockData((prevData) => ({ ...prevData, [name]: value }));
      if (value === "") {
        setError({ ...error, months: "Please select a time period" });
      } else {
        setError({ ...error, months: "" });
      }
    }
  };

  const lockTokens = async () => {
    if (!signer) {
      console.error("Signer not available.");
      toast.error("Please connect your wallet first");
      return;
    }

    const tokenLockerContractAddress =
      process.env.REACT_APP_TOKEN_LOCKER_CONTRACT_ADDRESS;

    if (!tokenLockerContractAddress) {
      console.error("Token locker contract address not configured");
      toast.error("Contract configuration error");
      return;
    }

    // Set loading state at the start
    setIsPopupOpen(true);

    try {
      // Create contract instance
      const tokenLockerContract = new ethers.Contract(
        tokenLockerContractAddress,
        tokenLockerAbi,
        signer
      );

      // Validate inputs
      if (!lockData.tokenQuantity || !lockData.months) {
        setIsPopupOpen(false);
        toast.error("Please enter both token quantity and lock duration");
        return;
      }

      // Convert inputs to BigNumber with proper decimal handling
      const amount = ethers.utils.parseUnits(
        lockData.tokenQuantity.toString(),
        2
      );
      const months = ethers.BigNumber.from(lockData.months);

      // Check if amount is valid
      if (amount.lte(0)) {
        setIsPopupOpen(false);
        toast.error("Token amount must be greater than 0");
        return;
      }

      console.log("Locking tokens...");

      // Check network connectivity
      const provider = signer.provider;
      await provider.getNetwork();

      const gasPrice = await provider.getGasPrice();
      console.log("Current gas price:", gasPrice.toString());

      // Estimate gas with increased timeout (3 minutes)
      const gasEstimate = await tokenLockerContract.estimateGas
        .lockTokens(amount, months)
        .catch((error) => {
          throw new Error(`Gas estimation failed: ${error.message}`);
        });

      // Add 30% buffer for mobile networks
      const gasLimit = gasEstimate.mul(150).div(100);
      // Send transaction with gas limit and slightly higher gas price for faster processing
      const tx = await tokenLockerContract.lockTokens(amount, months, {
        gasLimit: gasLimit,
        gasPrice: gasPrice.mul(120).div(100), // 10% higher gas price
      });

      console.log("Transaction sent:", tx.hash);
      // Wait for confirmation with longer timeout
      const receipt = await tx.wait();
      queryClient.invalidateQueries(["tokenBalance"]);
      queryClient.invalidateQueries(["userDetails"]);
      console.log("Transaction confirmed:", receipt);
      // Update UI and state
      setIsPopupOpen(false);
      setSuccess(true);
      setLockData({
        tokenQuantity: "",
        months: "",
      });
    } catch (error) {
      console.error("Error locking tokens:", error);
      setIsPopupOpen(false);
      setFailed(true);

      // Enhanced error handling for network issues
      if (
        error.message?.includes("network error") ||
        error.message?.includes("timeout")
      ) {
        toast.error(
          "Network is slow. Please try: 1) Switch to WiFi 2) Close other apps 3) Refresh page",
          { duration: 8000 }
        );
      } else if (error.message?.includes("insufficient funds")) {
        toast.error("Insufficient funds for transaction");
      } else if (error.code === 4001) {
        toast.error("Transaction rejected by user");
      } else if (error.code === -32603) {
        toast.error("Network error. Please check connection and try again");
      } else if (error.message?.includes("gas required exceeds allowance")) {
        toast.error("Insufficient funds for gas fees");
      } else if (error.message?.includes("nonce too low")) {
        toast.error("Please refresh the page and try again");
      } else if (error.message?.includes("replacement fee too low")) {
        toast.error("Network is congested. Please try again in a few minutes");
      } else {
        toast.error("Transaction failed.", { duration: 6000 });
      }
      setTransactionResult("Failed to lock tokens");
    }
  };

  const dataHandler = async (e) => {
    e.preventDefault();
    let valid = true;
    let newError = { tokenQuantity: "", months: "" };

    // Validate tokenQuantity
    if (!lockData.tokenQuantity.trim()) {
      newError.tokenQuantity = "Token quantity is required";
      valid = false;
    } else if (isNaN(lockData.tokenQuantity)) {
      newError.tokenQuantity = "Please enter a valid number";
      valid = false;
    } else if (parseFloat(lockData.tokenQuantity) > parseFloat(balance)) {
      newError.tokenQuantity = "Insufficient balance";
      valid = false;
    }

    // Validate months
    if (!lockData.months) {
      newError.months = "Please select a time period";
      valid = false;
    }

    setError(newError);

    if (valid) {
      await lockTokens();
    }
  };

  return (
    <>
      <div
        id="lock-token-section"
        className="bg-locking-bg w-full h-full bg-cover bg-no-repeat bg-center py-28 flex flex-col gap-28 rounded-[10px]"
      >
        <form
          onSubmit={dataHandler}
          className="flex flex-col gap-6 w-[86%] m-auto"
        >
          <div className="flex flex-col gap-1">
            <label className="text-[14px] font-medium leading-[17px]">
              Token Quantity
            </label>
            <input
              className={`px-2 py-3 rounded-[6px] text-[#000000] placeholder:text-[#9999A1] focus:outline-none focus:ring-2 ${
                error.tokenQuantity
                  ? "focus:ring-red-700 animate-shake"
                  : "focus:ring-[#0E7570]"
              }`}
              type="text"
              onChange={onChangeHandler}
              value={lockData.tokenQuantity}
              name="tokenQuantity"
              placeholder="Enter token quantity"
            />
            {error.tokenQuantity && (
              <p className="text-red-500 text-sm">{error.tokenQuantity}</p>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-[14px] font-medium leading-[17px]">
              Time Period
            </label>
            <select
              onChange={onChangeHandler}
              value={lockData.months}
              name="months"
              className={`px-2 py-3 rounded-[6px] text-[#000000] placeholder:text-[#9999A1] focus:outline-none focus:ring-2 ${
                error.months
                  ? "focus:ring-red-700 animate-shake"
                  : "focus:ring-[#0E7570]"
              }`}
            >
              <option value="" disabled>
                Select time period
              </option>
              <option value="3">3 Months</option>
              <option value="6">6 Months</option>
              <option value="9">9 Months</option>
              <option value="12">12 Months</option>
            </select>
            {error.months && (
              <p className="text-red-500 text-sm">{error.months}</p>
            )}
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-[#0E7570] text-white w-full justify-center flex gap-2 px-2 py-3 rounded-[6px]"
              disabled={!signer}
            >
              <div className="flex gap-1">
                Lock Now <img src={lock} alt="lock" />
              </div>
            </button>
          </div>
        </form>

        <div className="w-full flex justify-center">
          <img className="max-w-full" src={coinBox} alt="coinBox" />
        </div>
      </div>
      {isPopupOpen && (
        <TokenLockPopup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
        />
      )}
      {success && (
        <SuccessPopup
          message="Token Locked Successfully"
          isOpen={success}
          onClose={() => setSuccess(false)}
        />
      )}
      {failed && (
        <FaildPopup isOpen={failed} onClose={() => setFailed(false)} />
      )}
    </>
  );
};

export default LockingPeriod;
