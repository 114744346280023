import React, { useState } from "react";
import krishna from "../../assets/krishna.png";
import akshat from "../../assets/akshat.png";
import chirag from "../../assets/chirag.png";
import krutisha from "../../assets/krutisha.png";
import vasu from "../../assets/vj2.png";
import { Fade, Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import LinkedIn from "../../components/svg/Linked-in";

const OurTeam = () => {
  const [hoverIndex, setHoverIndex] = useState(null);

  const teams = [
    {
      image: krishna,
      name: "Ritik Shrivastava",
      title: "Vice President",
      link: "https://www.linkedin.com/in/ritik-shrivastava-6768a11a3",
    },
    {
      image: akshat,
      name: "Akshat Shah",
      title: "Chief Operations Officer",
      link: "https://www.linkedin.com/in/akshat-shah-94a30a122",
    },
    {
      image: chirag,
      name: "Chirag Rajput",
      title: "Chief Technical Officer (Blockchain)",
      link: "https://www.linkedin.com/in/chirag-rajput/",
    },
    {
      image: krutisha,
      name: "Krutisha Patel",
      title: "Lead Product Designer",
      link: "https://www.linkedin.com/in/krutisha-patel-345b8b218/",
    },
    {
      image: vasu,
      name: "Vasudeo Jha",
      title: "Senior Software Engineer",
      link: "https://www.linkedin.com/in/vasu-deo-jha",
    },
  ];

  return (
    <div>
      <Fade triggerOnce direction="down">
        <h2 className="text-[#0E7570] text-[32px] font-bold text-center">
          Meet Our Team
        </h2>
      </Fade>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 justify-items-center gap-y-6 gap-2 w-[90%] m-auto pb-2 mt-10 overflow-x-auto">
        <Zoom triggerOnce>
          {teams.map(({ image, name, title, link }, index) => (
            <div key={index} className="relative">
              <div className="w-[280px] sm:w-[220px] h-[300px] sm:h-[250px] overflow-hidden rounded-[10px]">
                <img
                  className="w-full h-full object-cover hover:scale-110 hover:rotate-2 transition-all ease-in-out duration-700"
                  src={image}
                  alt={name}
                  loading="lazy"
                />
              </div>

              <div className="bg-white w-auto m-auto flex flex-col rounded-[10px] items-center py-1">
                <p className="text-[#0A0A0A] text-[18px] font-semibold flex items-center cursor-pointer">
                  {name}
                </p>
                <p className="text-[#66666E] font-normal text-[14px]">
                  {title}
                </p>
                <div
                  className="rounded-full p-2 transition-all duration-300 cursor-pointer"
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                >
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <LinkedIn
                      bgColor={hoverIndex === index ? "#0077b5" : "black"}
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Zoom>
      </div>
    </div>
  );
};

export default OurTeam;
