import React from "react";
import cuffi from "../assets/cuffi-white.svg";
import linkedIn from "../assets/linkedInFooter.svg";
import twitter from "../assets/twitter.svg";
import insta from "../assets/insta.svg";
import discord from "../assets/discord.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const socialLinks = [
    {
      id: 1,
      src: linkedIn,
      alt: "LinkedIn",
      href: "https://www.linkedin.com/company/cuffi-shop/",
    },
    {
      id: 3,
      src: twitter,
      alt: "Twitter",
      href: "https://x.com/cuffi_shop",
    },

    {
      id: 4,
      src: insta,
      alt: "Instagram",
      href: "https://www.instagram.com/cuffi.shop/",
    },
    {
      id: 5,
      src: discord,
      alt: "discord",
      href: "https://discord.gg/xEX4RQUy",
    },
  ];

  return (
    <>
      <div className="bg-[#004442] pt-6 pb-3 w-full">
        <div className="w-[98%] m-auto flex flex-col sm:flex-row gap-4 justify-center">
          <div className="flex justify-center sm:justify-start">
            <Link to="/">
              <img src={cuffi} alt="cuffi" />
            </Link>
          </div>

          <p className="relative top-5 border-t border-[#CECECE] hidden sm:block sm:w-[25%] md:w-[45%] lg:w-[55%] xl:w-[60%]"></p>
          <div className="flex justify-center sm:justify-start gap-4">
            {socialLinks.map((link) => (
              <a
                key={link.id}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className="w-12 h-12 flex items-center justify-center transition-transform duration-300 transform hover:scale-110"
              >
                <img src={link.src} alt={link.alt} className="object-contain" />
              </a>
            ))}
          </div>
        </div>
        <div className="text-[#FFFFFF] font-medium text-[12px] leading-[14.52px] py-4 w-[86%] m-auto flex flex-col md:flex-row gap-4 md:gap-0 items-center md:justify-between">
          <p>Copyright ©2024 Cuffi. All rights reserved.</p>
          <p>Privacy Notice | Status | Cookie Preferences</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
