import React, { useState, useEffect } from "react";
import piechart1 from "../assets/pieChart/pieChart1.svg";
import piechart2 from "../assets/pieChart/pieChart2.svg";
import piechart3 from "../assets/pieChart/pieChart3.svg";
import piechart4 from "../assets/pieChart/pieChart4.svg";
import piechart5 from "../assets/pieChart/pieChart5.svg";
import piechart6 from "../assets/pieChart/pieChart6.svg";
import piechart7 from "../assets/pieChart/pieChart7.svg";
import piechart8 from "../assets/pieChart/pieChart8.svg";
import { Slide, Zoom } from "react-awesome-reveal";

const PieChart = () => {
  const images = [
    piechart1,
    piechart2,
    piechart3,
    piechart4,
    piechart5,
    piechart6,
    piechart7,
    piechart8,
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.5 }
    );

    const element = document.getElementById("pieChart");
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) observer.unobserve(element);
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    let index = 0;
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        if (prevIndex === images.length - 1) {
          clearInterval(interval);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }, 300);

    return () => clearInterval(interval);
  }, [isVisible, images.length]);

  return (
    <>
      <Zoom triggerOnce>
        <img
          src={images[currentImageIndex]}
          alt={`Pie chart ${currentImageIndex + 1}`}
          className="transition-all duration-600 ease-linear"
        />
      </Zoom>
    </>
  );
};

export default PieChart;
