import React from "react";
import forward from "../assets/forward.svg";
import backward from "../assets/backward.svg";

const Pagination = ({ totalPages, page, setPage, limit }) => {
  return (
    <>
      <div className="flex justify-end mr-10 mt-5 gap-2">
        <span
          className={`flex items-center py-1.5 px-3  border border-[#9999A1]  rounded-[6px] text-[#1A1A1A]  
            ${page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer"}
          `}
          onClick={page > 1 ? () => setPage(page - 1) : null}
        >
          <img src={backward} alt="backward" />
        </span>

        <span
          className={`px-3 py-1.5  border border-[#9999A1]  rounded-[6px] text-[#1A1A1A] bg-[#CDCDCD]  cursor-pointer
            `}
        >
          {page}
        </span>

        <span
          className={`flex item-center  py-1.5 px-3  border border-[#9999A1]  rounded-[6px] text-[#1A1A1A]  
            ${
              page === totalPages
                ? " cursor-not-allowed opacity-50"
                : "cursor-pointer"
            }
          `}
          onClick={page < totalPages ? () => setPage(page + 1) : null}
        >
          <img src={forward} alt="forward" />
        </span>
      </div>
    </>
  );
};

export default Pagination;
