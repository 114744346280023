import { configureStore } from "@reduxjs/toolkit";
import signerReducer from "./reducers/signerReducer";

const store = configureStore({
  reducer: {
    signer: signerReducer,
  },
});

export default store;
