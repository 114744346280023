import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./App.css";
import Index from "./pages/home/Index";
import { DashboardIndex } from "./pages/dashboard/DashboardIndex";
import DashboardLayout from "./components/clientDashboard/DashboardLayout";
import Dao from "./pages/dashboard/Dao";
import AboutUS from "./pages/about/AboutIndex";
import Reports from "./pages/dashboard/Reports";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import History from "./pages/dashboard/History";
import PageNotFound from "./pages/PageNotFound";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<AboutUS />} />
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<DashboardIndex />} />
            <Route path="history" element={<History />} />
            <Route path="dao" element={<Dao />} />
            <Route path="reports" element={<Reports />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <Toaster position="top-right" reverseOrder={true} />
    </QueryClientProvider>
  );
}

export default App;
