import React from "react";
import reward from "../../assets/rewards.png";
import { Zoom } from "react-awesome-reveal";
const ProportionalRewards = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${reward})` }}
        className="px-4 sm:px-20 py-10 bg-cover
                  bg-center bg-no-repeat w-full"
      >
        <Zoom triggerOnce>
          <h2 className="text-[#0E7570] font-bold text-[20px] lg:text-[32px] leading-[30px] lg:leading-[46px] text-center">
            Proportional Rewards: How Staking Works
          </h2>
        </Zoom>
        <Zoom triggerOnce>
          <div className="flex flex-col gap-8 text-[16px] leading-[26px] font-normal text-justify mt-10">
            <p>
              Cuffi's staking mechanism offers a reward distribution system that
              allocates benefits based on both the amount of tokens staked and
              the duration for which they are held. This proportional rewards
              system ensures that users who stake more tokens or commit to
              longer staking periods are rewarded with a higher share, thereby
              encouraging long-term participation and greater commitment within
              the ecosystem.
            </p>
            <p>
              One of the standout features of Cuffi's staking model is its
              non-custodial nature, which means that users maintain full control
              over their tokens at all times. Unlike custodial staking systems
              where tokens are handed over to a third party, here, users retain
              ownership throughout the staking period. Additionally, the entire
              process is transparent and fully verifiable on the blockchain,
              providing users with confidence in the system's fairness and
              security.
            </p>
            <p>
              The reward distribution is seamless and automated. There is no
              need for manual intervention from the pool creator, as rewards are
              calculated and distributed through smart contracts, making the
              process efficient and tamper-proof. This automation enhances the
              overall user experience, ensuring that participants receive their
              rewards promptly and fairly without any additional steps.
            </p>
          </div>
        </Zoom>
      </div>
    </>
  );
};

export default ProportionalRewards;
