import React from "react";
import visionImage from "../../assets/vision.png";
import { Slide, Zoom } from "react-awesome-reveal";

const Vision = () => {
  return (
    <>
      <div className="bg-white w-[90%] mx-auto">
        <div className="grid lg:grid-cols-2 gap-x-20 gap-y-10">
          <Zoom triggerOnce>
            <div className="flex flex-col gap-4 items-center lg:items-start justify-center h-full">
              <h2 className="text-[32px] text-[#0E7570] font-bold">
                Our Vision
              </h2>
              <p className="whitespace-normal text-[#0A0A0A] text-[18px] font-normal">
                We envision a world where coffee lovers are empowered by
                blockchain. With our token, you'll be able to buy, trade, and
                earn rewards while enjoying your favorite cup of coffee.
              </p>
            </div>
          </Zoom>
          <Slide triggerOnce direction="right">
            <div className="w-[100%]">
              <img
                className="w-full object-contain"
                src={visionImage}
                alt="visionImage"
                loading="lazy"
              />
            </div>
          </Slide>
        </div>
      </div>
    </>
  );
};

export default Vision;
