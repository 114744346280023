import React, { useEffect } from "react";

const FaildPopup = ({ isOpen, onClose }) => {
  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        onClose();
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [isOpen, onClose]);

  return (
    <>
      <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-20 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-[300px] sm:w-[350px] h-[200px] flex flex-col justify-center items-center gap-4 text-center">
          <p className="text-red-600 text-lg font-semibold">
            Failed to lock tokens
          </p>
          <p className="text-md font-semibold">Please try again</p>
        </div>
      </div>
    </>
  );
};

export default FaildPopup;
