import { MetaMaskSDK } from "@metamask/sdk";
import MetaMaskOnboarding from "@metamask/onboarding";
import { ethers } from "ethers";
import { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSignerInfo } from "../../store/reducers/signerReducer";

const useMetaMask = () => {
  const dispatch = useDispatch();
  const [provider, setProvider] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState("");
  const [userBalance, setUserBalance] = useState("");
  const [signer, setSigner] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const msdk = useRef(null);
  const onboarding = useRef(null);

  const amoyNetworkDetails = {
    chainId: "0x13882", // Polygon Amoy Testnet ID
    chainName: "Polygon Amoy Testnet",
    rpcUrls: ["https://rpc-amoy.polygon.technology/"],
    nativeCurrency: { name: "Polygon", symbol: "POL", decimals: 18 },
    blockExplorerUrls: ["https://amoy.polygonscan.com/"],
  };

  // Check if the user is on a mobile device
  useEffect(() => {
    const checkMobile = () =>
      /Android|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(navigator.userAgent);
    setIsMobile(checkMobile());
  }, []);

  useEffect(() => {
    if (isMobile && !msdk.current) {
      msdk.current = new MetaMaskSDK({
        dappMetadata: {
          name: "cuffi",
          url: "http://localhost:3000",
        },
      });
    } else if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, [isMobile]);

  // Handle wallet connection
  const connectWallet = async () => {
    try {
      let currentProvider;
      if (isMobile) {
        console.log("Initializing MetaMask SDK for mobile...");
        currentProvider = msdk.current.getProvider();
        setProvider(currentProvider);

        if (!currentProvider) {
          console.log("Redirecting to MetaMask app via deep link...");
          const dappUrl = window.location.href.replace(/^https?:\/\//, "");
          const metamaskAppDeepLink = `https://metamask.app.link/dapp/${dappUrl}`;
          window.location.href = metamaskAppDeepLink;
          // return;
        }
      } else {
        console.log("Detecting MetaMask provider for desktop...");
        currentProvider =
          window.ethereum.providers?.find((p) => p.isMetaMask) ||
          window.ethereum;

        if (!currentProvider) {
          console.log("MetaMask not installed. Starting onboarding...");
          onboarding.current?.startOnboarding();
          return false;
        }
      }
      if (currentProvider) {
        const provider2 = new ethers.providers.Web3Provider(currentProvider);
        const signer = provider2.getSigner();
        console.log(signer, "fromhooks");
        dispatch(setSignerInfo(signer));
        setProvider(currentProvider);
      }

      const accounts = await currentProvider.request({
        method: "eth_requestAccounts",
      });

      if (!accounts.length) throw new Error("No accounts connected.");
      const checksummedAddress = ethers.utils.getAddress(accounts[0]);
      setDefaultAccount(checksummedAddress);
      localStorage.setItem("address", checksummedAddress);
      navigate("/dashboard");
      const currentChainId = await currentProvider.request({
        method: "eth_chainId",
      });

      if (currentChainId !== amoyNetworkDetails.chainId) {
        await switchToAmoyNetwork(currentProvider);
      }
      await getUserBalance(accounts[0], currentProvider);
    } catch (err) {
      return false;
      console.error("Error connecting to MetaMask:", err);
      setError("Error connecting to MetaMask. Please try again.");
    }
  };

  // Switch to Amoy Testnet
  const switchToAmoyNetwork = async (provider) => {
    try {
      console.log("Attempting to switch network to:", amoyNetworkDetails);
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: amoyNetworkDetails.chainId }],
      });
      console.log("Successfully switched to Amoy Testnet.");
    } catch (error) {
      console.error("Error while switching network:", error);

      if (error.code === 4902) {
        try {
          console.log("Network not found. Adding Amoy Testnet...");
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [amoyNetworkDetails],
          });
          console.log("Amoy Testnet added successfully.");
        } catch (addError) {
          console.error("Failed to add Amoy Testnet:", addError);
          setError("Failed to add Amoy Testnet. Please try manually.");
        }
      } else {
        setError("Failed to switch network. Please try again.");
      }
    }
  };

  // Fetch user balance
  const getUserBalance = async (address) => {
    const provider = new ethers.providers.Web3Provider(provider);
    try {
      const tokenAddress = "0xb05710A9bd9612a2a85C9010c65C64de259950b3";

      // Minimal ABI for balance check
      const tokenABI = [
        "function balanceOf(address) view returns (uint256)",
        "function decimals() view returns (uint8)",
        "function symbol() view returns (string)",
      ];

      // Create a contract instance
      const contract = new ethers.Contract(tokenAddress, tokenABI, provider);

      // First verify we can connect to the contract
      const symbol = await contract.symbol();
      console.log("Token Symbol:", symbol);

      // Get decimals first
      const decimals = await contract.decimals();
      console.log("Token Decimals:", decimals);

      // Get balance
      const balance = await contract.balanceOf(address);
      console.log("Raw balance:", balance.toString());

      // Format the balance with proper decimals
      const formattedBalance = ethers.utils.formatUnits(balance, decimals);
      console.log("Formatted balance:", formattedBalance);

      setUserBalance(formattedBalance);
      localStorage.setItem("cuffiBalance", formattedBalance);

      return formattedBalance;
    } catch (error) {
      // More detailed error logging
      console.error("Error details:", {
        message: error.message,
        code: error.code,
        data: error.data,
      });

      setError(
        "Failed to fetch token balance. Please verify you're connected to Polygon network."
      );
      throw error;
    }
  };
  //  handle disconnect
  const handleDisconnect = (error) => {
    console.error("Wallet disconnected:", error);
    setProvider(null);
    setDefaultAccount("");
    setUserBalance("");
    localStorage.clear();
    window.location.reload();
  };

  // Handle accounts and network changes
  useEffect(() => {
    if (!provider && !window.ethereum) return;

    const activeProvider = provider || window.ethereum;

    const handleAccountsChanged = (accounts) => {
      if (accounts.length > 0) {
        const checksummedAddress = ethers.utils.getAddress(accounts[0]);
        setDefaultAccount(checksummedAddress);
        localStorage.setItem("address", checksummedAddress);
      } else {
        handleDisconnect("No accounts connected.");
      }
    };

    const handleChainChanged = () => window.location.reload();

    const handleDisconnectEvent = (error) => handleDisconnect(error);

    // Attach event listeners
    activeProvider.on("accountsChanged", handleAccountsChanged);
    activeProvider.on("chainChanged", handleChainChanged);
    activeProvider.on("disconnect", handleDisconnectEvent);

    return () => {
      // Remove event listeners
      activeProvider.removeListener("accountsChanged", handleAccountsChanged);
      activeProvider.removeListener("chainChanged", handleChainChanged);
      activeProvider.removeListener("disconnect", handleDisconnectEvent);
    };
  }, [provider]);

  return {
    provider,
    defaultAccount,
    userBalance,
    connectWallet,
    error,
    setProvider,
  };
};

export default useMetaMask;
