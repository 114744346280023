import { createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState = {
  signer: null,
};

// Create the slice
const signerSlice = createSlice({
  name: "signer",
  initialState,
  reducers: {
    setSignerInfo(state, action) {
      state.signer = action.payload; // Set the signer info from the action payload
    },
  },
});

// Export the actions and reducer
export const { setSignerInfo } = signerSlice.actions; // Export actions directly
export default signerSlice.reducer; // Export reducer as default
