import axios from "axios";

export const getUserDetails = async (address, page = 1, limit = 10) => {
  try {
    const response = await axios.post(
      `https://backend.cuffi.shop/getUserDetails?page=${page}&limit=${limit}`,
      {
        address: address,
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        throw new Error(
          error.response.data.message ||
            `Server responded with status: ${error.response.status}`
        );
      } else if (error.request) {
        console.error("No response received from server:", error.request);
        throw new Error(
          "No response received from the server. Please try again later."
        );
      } else {
        console.error("Error setting up the request:", error.message);
        throw new Error(
          "Failed to fetch user details. Please check your connection."
        );
      }
    } else {
      console.error("Unexpected Error:", error);
      throw new Error("An unexpected error occurred. Please try again later.");
    }
  }
};
