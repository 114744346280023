import React, { useState } from "react";
import BarChart2 from "../BarChart";

const ProfitDistribution = () => {
  const datasets = [
    {
      label: "FY25-Q1",
      data: [
        { name: "Revenue", value: 0 },
        { name: "Profit", value: 0 },
        { name: "Distribution", value: 0 },
      ],
    },
    {
      label: "FY25-Q2",
      data: [
        { name: "Revenue", value: 0 },
        { name: "Profit", value: 0 },
        { name: "Distribution", value: 0 },
      ],
    },
    {
      label: "FY25-Q3",
      data: [
        { name: "Revenue", value: 0 },
        { name: "Profit", value: 0 },
        { name: "Distribution", value: 0 },
      ],
    },
  ];

  const [chartData, setChartData] = useState(datasets[0].data);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-[30%_70%] justify-items-center py-4 lg:py-8 md:px-4 lg:px-8 rounded-[10px] border-[1px]">
      <p className="lg:col-span-2 grid justify-self-start text-[#0E7570] text-[20px] font-semibold leading-[24px] pl-4 sm:pl-2">
        Profit Distribution
      </p>
      <div className="flex lg:flex-col gap-2 sm:gap-6 mt-10 justify-center">
        {datasets.map((dataset, index) => (
          <button
            key={index}
            className="shadow-custom-button hover:bg-[#EEEEF0] rounded-[14px] text-[14px] sm:text-[18px] font-medium px-4 py-2"
            onClick={() => setChartData(dataset.data)}
          >
            {dataset.label}
          </button>
        ))}
      </div>
      <div className="flex flex-col">
        <div className="mt-10 flex justify-center items-center">
          <BarChart2 data2={chartData} />
        </div>
      </div>
      <p className="px-4 md:px-2">
        *The data will be refreshed every quarter from FY25-Q1
      </p>
    </div>
  );
};

export default ProfitDistribution;
